<template>
  <div class="network-vlt" :class="(isOnline) ? 'online-div':'offline-div'">
    <img
      :src="imagesrc"
      alt="image"
      class="image"
      :class="!isOnline?'offline-image':''"
      loading="lazy"
    />
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: ["isOnline"],
  data() {
    return {
      message: "",
      onlinePicture: require("@/assets/icons/online.svg"),
      offlinePicture: require("@/assets/icons/offline.svg")
    };
  },
  watch: {
    isOnline() {
      this.setBasic();
    }
  },
  computed:{
    imagesrc(){
       return this.isOnline?this.onlinePicture:this.offlinePicture;
    }
  },
  methods: {
    setBasic() {
      if (this.isOnline) {
        this.message = "Back to online";
      } else {
        this.message = "You are offline, Please Check your connectivity";
      }
    }
  },
  created() {
    this.setBasic();
  }
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.network-vlt {
  color: $clr-white;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  display: flex;
  justify-content: center;
}
.online-div {
  background-color: $clr-color-green;
}
.offline-div {
  background-color: $theme-primary;
}
.image {
  margin-right: 10px;
}
.offline-image {
  width: 20px;
}
p {
  padding: 5px 0px;
}
</style>